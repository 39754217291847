import styles from "./engagmentItem.module.css";
import { useSession } from "next-auth/react";
var toFixed = require("tofixed");

interface Props {
     icon: string;
     itemTitle: string;
     itemValue: string;
     baseCoinValue: string | null;
     currencySymbol: boolean;
}

const EngagmentNormalItem: React.FC<Props> = ({ icon, itemTitle, itemValue, baseCoinValue }) => {
     const { data: session } = useSession();

     return (
          <div className={styles.engagementItem}>
               <div className={styles.engagementItemIcon}>
                    <span className="material-icons">{icon}</span>
               </div>
               <div className={styles.engagementItemContent}>
                    <span>{itemTitle}</span>
                    {itemValue && <h5 title={itemValue}>{session?.user?.currency?.symbol ? session.user.currency.symbol + toFixed(itemValue, 5) : toFixed(itemValue, 4)}</h5>}{" "}
                    {baseCoinValue && <p title={baseCoinValue}>BTC {toFixed(baseCoinValue, 5)}</p>}
               </div>
          </div>
     );
};

export default EngagmentNormalItem;
