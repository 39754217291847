import PrimaryInput from "@/components/base/inputs/primaryInput";
import Image from "next/image";
import Link from "next/link";
import styles from "./exchangeConnecting.module.css";
import { useSession } from "next-auth/react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CreateWallet, syncWallet } from "@/_core/services/walletsService";
import { ExchangeType } from "@/_core/models/exchange_type";
import lottie from "lottie-web";

/* interface Exchange {
  id: number;
  name: string;
  exchangeIcon: string;
  description: string;
  credentialInfo: Record<string, string>;
} */

interface Props {
     selectedExchange: ExchangeType;
     setSelectedExchange: React.Dispatch<React.SetStateAction<ExchangeType | null>>;
     openModal: boolean;
     setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
     setWalletRefetch: React.Dispatch<React.SetStateAction<boolean>>;
     walletRefetch: boolean;
}

const ExchanegConnecting: React.FC<Props> = ({ selectedExchange, setSelectedExchange, openModal, setOpenModal, walletRefetch, setWalletRefetch }) => {
     const { data: session } = useSession();
     const { t } = useTranslation("atwallet");
     const [walletName, setWalletName] = useState<string>("");
     const [credentialInfo, setCredentialInfo] = useState<Record<string, string>>({});
     const [privacyAgreement, setPrivacyAgreement] = useState<boolean>(false);
     const [error, setError] = useState<string>("");
     const [loading, setLoading] = useState<boolean>(false); // Added loading state
     const [success, setSuccess] = useState<boolean>(false); // Added success state

     const animationContainer = useRef<HTMLDivElement | null>(null);
     const successContainer = useRef<HTMLDivElement | null>(null);
     useEffect(() => {
          let animationInstance: any;

          // Initialize Lottie animation only if loading is true and the container is defined
          if (loading && animationContainer.current) {
               animationInstance = lottie.loadAnimation({
                    container: animationContainer.current,
                    renderer: "svg",
                    loop: true,
                    autoplay: true,
                    path: "/animations/animation.json",
               });
          }

          // Cleanup function to destroy the animation when the component is unmounted
          return () => {
               if (animationInstance) {
                    animationInstance.destroy();
               }
          };
     }, [loading]);

     useEffect(() => {
          let successInstance: any;

          // Initialize Lottie animation only if loading is true and the container is defined
          if (success && successContainer.current) {
               successInstance = lottie.loadAnimation({
                    container: successContainer.current,
                    renderer: "svg",
                    loop: false,
                    autoplay: true,
                    path: "/animations/successfull.json",
               });
          }

          // Cleanup function to destroy the animation when the component is unmounted
          return () => {
               if (successInstance) {
                    successInstance.destroy();
               }
          };
     }, [success]);

     // Function to handle credential input changes
     const handleCredentialChange = (key: string, value: string) => {
          setCredentialInfo((prev) => ({
               ...prev,
               [key]: value,
          }));
     };

     // Handle "Connect" button click
     const handleConnect = async () => {
          // Validate that all fields are filled
          if (!walletName) {
               setError("Please enter a wallet name.");
               return;
          }
          if (!privacyAgreement) {
               setError("Please agree to the privacy policy.");
               return;
          }
          if (Object.keys(credentialInfo).length !== Object.keys(selectedExchange.credentialInfo).length) {
               setError("Please fill in all credential fields.");
               return;
          }

          // Clear any previous errors
          setError("");
          setLoading(true); // Start loading animation

          // If all fields are valid, make the API call
          if (session?.access_token && session?.user) {
               const currency = session.user.currency.id;

               try {
                    // Call CreateWallet API
                    const response = await CreateWallet(session.access_token, credentialInfo, walletName, selectedExchange.id, currency);

                    // Stop loading animation and show success if wallet creation is successful
                    setLoading(false);
                    setSuccess(true);
                    // Sync the wallet if response contains exchangeId
                    if (response?.id) {
                         await syncWallet(session.access_token, response.id);
                    }
               } catch (err) {
                    setLoading(false);
                    setWalletRefetch(true);
                    setError("Failed to create wallet. Please try again.");
               }
          }
     };

     return (
          <div>
               {loading && (
                    <div className={styles.loading_container}>
                         <div ref={animationContainer}></div>
                         <h4>Please wait while we connect your exchange.</h4>
                         <h3>This process will complete shortly.</h3>
                         <Image src="/images/loading.svg" alt="spinner" width={56} height={56} />
                    </div>
               )}

               {!loading && !success && (
                    <div className={styles.selected_wallet_container}>
                         <Image src={selectedExchange.exchangeLogo} width={130} height={32} alt={selectedExchange.name} />

                         {/* <p className={styles.wallet_description}>
            {t(`text.ex_text.${selectedExchange.name}`)}
          </p> */}
                         <div className="horizontal_line"></div>
                         <div className={styles.inputs_container}>
                              {/* Wallet Name input always at the top */}
                              <PrimaryInput
                                   type="text"
                                   icon="api_black"
                                   inputName="Wallet Name"
                                   placeholder="Your Wallet Name"
                                   value={walletName} // Use walletName state here
                                   onChange={(e: any) => setWalletName(e.target.value)} // Update walletName state
                              />

                              {/* Dynamically generated credentialInfo inputs */}
                              {Object.entries(selectedExchange.credentialInfo).map(([key, value]) => (
                                   <PrimaryInput
                                        key={key}
                                        type="text"
                                        icon="api_black"
                                        inputName={key.charAt(0).toUpperCase() + key.slice(1)}
                                        placeholder={`Your ${key.charAt(0).toUpperCase() + key.slice(1)}`}
                                        value={credentialInfo[key] || ""} // Ensure correct binding to credentialInfo state
                                        onChange={
                                             (e: any) => handleCredentialChange(key, e.target.value) // Update credentialInfo state
                                        }
                                   />
                              ))}
                         </div>

                         {/* Error message */}
                         {error && <p className={styles.error_message}>{error}</p>}

                         <div className={styles.privacy_agreement}>
                              <button className="noneBackButton" onClick={() => setPrivacyAgreement(!privacyAgreement)}>
                                   <span className="material-icons-outlined">{privacyAgreement ? "check_box" : "check_box_outline_blank"}</span>
                              </button>

                              <p>
                                   You have read and accept the{" "}
                                   <Link href="https://atwallets.com/privacy-policy" target="_blank">
                                        Privacy Agreement
                                   </Link>
                              </p>
                         </div>

                         <div className={styles.modal_actions}>
                              <button
                                   className="secondaryButton"
                                   onClick={() => {
                                        setOpenModal(!openModal);
                                        setSelectedExchange(null);
                                   }}
                              >
                                   Cancel
                              </button>
                              <button className="primaryButton" onClick={handleConnect}>
                                   Connect
                              </button>
                         </div>
                    </div>
               )}

               {success && (
                    <div className={styles.success_container}>
                         <div ref={successContainer}></div>

                         <h4>Exchange connected successfully!</h4>
                         <h3> Your wallet has been added.</h3>
                         <button
                              className="primaryButton"
                              onClick={() => {
                                   setOpenModal(!openModal);
                                   setSelectedExchange(null);
                                   setWalletRefetch(true);
                              }}
                         >
                              Done
                         </button>
                    </div>
               )}
          </div>
     );
};

export default ExchanegConnecting;
