import Image from "next/image";
import styles from "./addPortfolioModal.module.css";
import { useEffect, useState } from "react";
import { useSession, signIn } from "next-auth/react";
import { getExchanges } from "@/_core/services/exchangeService";
import { useTranslation } from "react-i18next";
import ExchanegConnecting from "./exchangeConnecting/exchangeConnecting";
import { ExchangeType } from "@/_core/models/exchange_type";
import useScreenSize from "@/_core/utils/useScreenSize";

interface AddWalletModalProps {
	walletRefetch: boolean;
	openAddPortfoliotModal: boolean;
	setWalletRefetch: React.Dispatch<React.SetStateAction<boolean>>;
	setOpenAddPortfoliotModal: React.Dispatch<React.SetStateAction<boolean>>;
	selectedExchange: ExchangeType | null;
	setSelectedExchange: React.Dispatch<
		React.SetStateAction<ExchangeType | null>
	>; // Fixed type
}

const AddPortfolioModal: React.FC<AddWalletModalProps> = ({
	openAddPortfoliotModal,
	setOpenAddPortfoliotModal,
	setSelectedExchange,
	selectedExchange,
	setWalletRefetch,
	walletRefetch,
}) => {
	const { data: session, status } = useSession();
	const { t } = useTranslation("atwallet");
	const screenSize = useScreenSize();
	const [exchanges, setExchanges] = useState<ExchangeType[]>([]);

	const [error, setError] = useState<string | null>(null);
	const [modalStep, setModalStep] = useState<number>(0);
	const [privacyAgreement, setPrivacyAgreement] = useState<boolean>(false);

	useEffect(() => {
		const fetchExchanges = async () => {
			if (session?.access_token) {
				try {
					const data = await getExchanges(session.access_token, 1, 100); // Assume getExchanges expects access_token
					setExchanges(data.exchanges);
				} catch (error) {
					setError(
						error instanceof Error
							? error.message
							: "An unexpected error occurred",
					);
				}
			} else {
				signIn(); // Redirect to login if no session
			}
		};

		fetchExchanges();
	}, [session]);

	return (
		exchanges.length > 0 && (
			<div className={styles.modal_background}>
				<div
					className={
						screenSize === "large"
							? styles.modal_wrapper
							: styles.resModal_wrapper
					}
				>
					<div className={styles.modal_header}>
						<h3>
							{modalStep == 0
								? t("text.add-wallet.exchange-list")
								: t("text.add-wallet.connecting-exchange")}
						</h3>
						<button
							className="noneBackButton"
							onClick={() => setOpenAddPortfoliotModal(!openAddPortfoliotModal)}
						>
							<span className="material-icons">close</span>
						</button>
					</div>
					{modalStep == 0 && (
						<div className={styles.wallet_list_container}>
							{exchanges.length > 0 ? (
								exchanges.map((exchange) => (
									<div
										key={exchange.id}
										className={styles.wallet_table}
									>
										<div className={styles.exchange}>
											<Image
												alt={exchange.exchangeLogo}
												src={exchange.exchangeLogo}
												width={90}
												height={24}
											/>
										</div>
										<button
											className="primaryButton"
											onClick={() => {
												setModalStep(1);
												setSelectedExchange(exchange);
											}}
										>
											{t("text.buttons.connect-btn")}
										</button>
									</div>
								))
							) : (
								<p>{t("text.add-wallet.exchaneListMessage")}</p>
							)}
						</div>
					)}
					{modalStep == 1 && selectedExchange && (
						<ExchanegConnecting
							walletRefetch={walletRefetch}
							setWalletRefetch={setWalletRefetch}
							setSelectedExchange={setSelectedExchange}
							selectedExchange={selectedExchange}
							openModal={openAddPortfoliotModal}
							setOpenModal={setOpenAddPortfoliotModal}
						/>
					)}
				</div>
			</div>
		)
	);
};

export default AddPortfolioModal;
