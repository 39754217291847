interface Transaction {
    name: string;
    deposit: string;
    withdraw: string;
  }
  
 export function findLargestDeposit(arr: Transaction[]): number {
    return arr.reduce((max, obj) => {
      const deposit = parseFloat(obj.deposit); // Convert deposit to a number
      return deposit > max ? deposit : max;
    }, 0); // Initialize max as 0
  }
  